::selection {
  background: rgba(114, 105, 239, 0.25);
}

::-moz-selection {
  background: rgba(114, 105, 239, 0.25);
}

body {
  padding-bottom: env(safe-area-inset-bottom);
  padding-top: env(safe-area-inset-top);
  overflow: hidden;

  &[data-theme="dark"] {
    //BODY
    background-color: #303841 !important;
    color: #a6b0cf;

    .custom-hover {
      &:hover {
        a {
          color: #7a7f9a;
        }
      }
    }

    // BADGE
    .badge-dark {
      color: #36404a;
    }
    // BREADCRUMB
    .breadcrumb-item {
      color: #e1e9f1;
    }
    // BUTTONS
    .btn-dark,
    .btn-secondary {
      color: #272c3b !important;
    }

    .btn-block {
      width: 100%;
    }

    .btn-outline-light {
      color: #f8f9fa;
    }
    //CARD
    .card {
      background-color: #262e35;
      border: 0 solid #36404a;
    }

    .card-header {
      background-color: #36404a;
      border-bottom: 0 solid #36404a;
    }

    .card-drop {
      color: #a6b0cf;
    }

    .card-title-desc {
      color: #abb4d2;
    }
    // FORM
    .custom-control-label::before {
      background-color: tint-color(#272c3b, 2%);
    }
    .form-control {
      color: #9aa1b9;
    }
    // HELPER
    .social-list-item {
      border: 2px solid #9aa1b9;
      color: #9aa1b9;

      &:hover {
        color: #abb4d2;
        background-color: #272c3b;
      }
    }
    // NAV
    .nav-pills,
    .nav-tabs {
      > li {
        > a {
          color: #e1e9f1;
        }
      }
    }

    .nav-pills {
      > a {
        color: #e1e9f1;
      }
    }

    .nav-tabs-custom {
      border-bottom: 2px solid #36404a;
    }
    //REBOOT
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #e1e9f1;
    }
    // AUTHENTICATION
    .logo-dark {
      display: none;
    }

    .logo-light {
      display: block;
    }
    // CHAT LEFT SIDEBAR
    .chat-leftsidebar {
      height: 100vh;
      background-color: #303841;
    }

    .chat-list {
      li {
        &.active {
          a {
            background-color: #36404a;
          }
        }

        a {
          color: #abb4d2;
          border-top: 1px solid #303841;

          &:hover {
            background-color: #36404a;
          }
        }
      }
    }
    // UPLOAD SPINNER
    .ppc-percents {
      background-color: #262e35;
    }

    .progress-spinner {
      background-color: #303841;
      &.gt-50 {
        background: #7269ef;
        .ppc-progress-fill {
          background: #36404a;
        }
      }
    }

    .ppc-progress {
      .gt-50 & {
        .ppc-progress-fill {
          background: #303841;
        }
      }
    }

    .chat-user-img {
      .user-status {
        border: 2px solid #262e35;
      }
    }

    .user-entities {
      background-color: #262e35;
    }

    // USER CHAT
    .user-chat {
      background-color: #262e35;
    }

    .user-chat-nav {
      .nav-btn {
        color: #abb4d2;
      }
    }

    .chat-conversation {
      .chat-day-title {
        .title {
          background-color: #36404a;
        }

        &:before {
          background-color: #36404a;
          display: none !important;
        }
      }

      .conversation-list {
        .dropdown {
          .dropdown-toggle {
            color: #abb4d2;
          }
        }
      }

      .right {
        .chat-time {
          color: #abb4d2;
        }

        .conversation-list {
          .ctext-wrap {
            .ctext-wrap-content {
              background-color: #36404a;
              color: #eff2f7;

              &:before {
                border-top-color: #36404a;
                border-right-color: #36404a;
              }

              .reply-block {
                background-color: rgba($white, 0.075);
                color: rgba($white, 0.8);
              }
            }
          }
        }
      }
    }
    // USER PROFILE DETAILS
    .user-profile-sidebar {
      background-color: #262e35;
      border-left: 4px solid #262e35;
      border-left: 4px solid #36404a;
    }
    // CUSTOM
    .side-menu {
      background-color: #36404a;

      .logo-dark {
        display: none;
      }

      .logo-light {
        display: block;
      }
    }

    .side-menu-nav {
      .nav-item {
        .nav-link {
          color: #a6b0cf;

          &.active {
            background-color: #3e4a56;
            color: #7269ef;
          }
        }

        &.show > .nav-link {
          background-color: #3e4a56;
        }
      }

      .profile-user {
        background-color: #36404a;
      }

      .avatar.profile-user {
        background-color: transparent;
      }
    }
    // DROPDOWN
    .dropdown-menu {
      background-color: #313a43 !important;
      border-color: #3e4851 !important;
      color: #9aa1b9;

      button,
      span {
        color: #9aa1b9 !important;
        background-color: transparent;

        &:focus,
        &:hover,
        &.active {
          color: #ecedee;
          text-decoration: none;
          background-color: #36404a;
        }
      }

      .dropdown-divider {
        border-top-color: #36404a;
      }
    }
    // CUSTOM  ACCORDION
    .custom-accordion {
      .card {
        background-color: #303841;
        background-color: #262e35;
      }

      h5 {
        color: #e1e9f1;
      }
    }
    // UTILITIES
    .text-muted {
      color: #9aa1b9 !important;
    }

    .border,
    .border-bottom,
    .border-left,
    .border-right,
    .border-top {
      border-color: #36404a;
    }

    .input-group-text {
      color: #9aa1b9;
      background-color: #36404a;
      border: 1px solid #36404a;
    }

    .bg-light {
      background-color: #36404a !important;
    }

    .border-light {
      border-color: #e6ebf5 !important;
      border-color: #36404a !important;
    }

    .emoji-mart-dark .emoji-mart-search input {
      background-color: #2f2f2f !important;
      border-color: #555453 !important;
    }

    .emoji-mart-dark .emoji-mart-category-label span {
      background-color: #222 !important;
      color: #fff !important;
    }

    .modal-content {
      background-color: #262e35;
      border-color: #36404a;

      .modal-header,
      .modal-footer {
        border-color: #36404a;
      }

      .form-control {
        background-color: #36404a !important;
        border-color: #36404a;
      }

      .close {
        color: #e1e9f1;
      }
    }

    .chatInputWrapper {
      background-color: #262e35;
    }

    .account-pages .input-group.bg-soft-light {
      background-color: transparent !important;
    }

    .account-pages input.bg-soft-light {
      background: #36404a !important;
    }

    .user-entities {
      .card-lists {
        .card-list-item {
        }
        &.grid {
          .card-list-progress {
            border-top: 1px solid #36404a;
          }
        }
      }
    }

    .form-control:disabled,
    .form-control[readonly] {
      background-color: var(--bs-input-bg);
    }

    .tag-input {
      .tag {
        color: var(--bs-dark);
      }
    }
  }

  &[data-theme="light"] {
    background-color: #f7f7ff;
    color: #495057;

    .custom-hover {
      &:hover {
        a {
          color: #ffffff;
        }
      }
    }

    .custom-accordion {
      background-color: transparent !important;
    }

    .badge-dark {
      color: #e6ebf5;
    }

    .breadcrumb-item {
      color: #495057;
    }

    .btn-dark,
    .btn-secondary {
      color: #f5f7fb !important;
    }

    .btn-block {
      width: 100%;
    }

    .btn-outline-light {
      color: #212529;
    }

    .card {
      background-color: #fff;
    }

    .card-header {
      background-color: #f5f7fb;
      border-bottom: 0 solid #f5f7fb;
    }

    .card-drop {
      color: #e6ebf5;
    }

    .card-title-desc {
      color: #7a7f9a;
    }

    .custom-control-label::before {
      background-color: tint-color(#f5f7fb, 2%);
    }

    .social-list-item {
      border: 2px solid #adb5bd;
      color: #adb5bd;

      &:hover {
        color: #7a7f9a;
        background-color: #f5f7fb;
      }
    }

    .nav-pills,
    .nav-tabs {
      > li {
        > a {
          color: #495057;
        }
      }
    }

    .nav-pills {
      > a {
        color: #495057;
      }
    }

    .nav-tabs-custom {
      border-bottom: 2px solid #f0eff5;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #495057;
    }

    .logo-dark {
      display: block;
    }

    .logo-light {
      display: none;
    }

    .chat-leftsidebar {
      height: 100vh;
      background-color: #f5f7fb;
    }

    .chat-list {
      li {
        &.active {
          a {
            background-color: #e6ebf5;
          }
        }

        a {
          color: #7a7f9a;
          border-top: 1px solid #f5f7fb;

          &:hover {
            background-color: #e6ebf5;
          }
        }
      }
    }

    .ppc-percents {
      background-color: #ffffff;
    }

    .progress-spinner {
      background-color: #e6ebf5;
      &.gt-50 {
        background: #7269ef;
        .ppc-progress-fill {
          background: #e6ebf5;
        }
      }
    }

    .ppc-progress {
      .gt-50 & {
        background: #7269ef;
        .ppc-progress-fill {
          background: #e6ebf5;
        }
      }
    }

    .chat-user-img {
      .user-status {
        border: 2px solid #ffffff;
      }
    }

    .user-entities {
      background-color: #ffffff;
    }

    .user-chat {
      background-color: #ffffff;
    }

    .user-chat-nav {
      .nav-btn {
        color: #7a7f9a;
      }
    }

    .chat-conversation {
      .chat-day-title {
        .title {
          background-color: #f0eff5;
        }

        &:before {
          background-color: #f0eff5;
          display: none !important;
        }
      }

      .conversation-list {
        .dropdown {
          .dropdown-toggle {
            color: #7a7f9a;
          }
        }
      }

      .right {
        .chat-time {
          color: #7a7f9a;
        }

        .conversation-list {
          .ctext-wrap {
            .ctext-wrap-content {
              background-color: #f5f7fb;
              color: #343a40;

              &:before {
                border-top-color: #f5f7fb;
                border-right-color: #f5f7fb;
              }
            }
          }
        }
      }
    }

    .user-profile-sidebar {
      background-color: #ffffff;
    }

    .side-menu {
      background-color: #ffffff;

      .logo-dark {
        display: block;
      }

      .logo-light {
        display: none;
      }
    }

    .side-menu-nav {
      .nav-item {
        .nav-link {
          color: #878a92;

          &.active {
            background-color: #f7f7ff;
            color: #7269ef;
          }
        }

        &.show > .nav-link {
          background-color: #f7f7ff;
        }
      }

      .profile-user {
        background-color: #f0eff5;
      }

      .avatar.profile-user {
        background-color: transparent;
      }
    }

    .dropdown-menu {
      background-color: #ffffff !important;
      border-color: #ffffff !important;
      button,
      span {
        &:focus,
        &:hover,
        &.active {
          background-color: #7269ef;
          color: #ffffff;
        }
      }
    }

    .custom-accordion {
      background-color: #ffffff;
    }

    .text-muted {
      color: #7a7f9a !important;
    }

    .border,
    .border-bottom,
    .border-left,
    .border-right,
    .border-top {
      border-color: #f0eff5;
    }

    .input-group-text {
      color: #9aa1b9;
      background-color: #f5f7fb;
      border: 1px solid #e6ebf5;
    }

    .bg-light {
      // background-color: #f0eff5 !important;
      background-color: #e6ebf5 !important;
    }

    .border-light {
      border-color: #e6ebf5 !important;
    }

    .chatInputWrapper {
      background-color: #ffffff;
    }

    .user-entities {
      .card-lists {
        .card-list-item {
        }
        &.grid {
          .card-list-progress {
            border-top: 1px solid #f0eff5;
          }
        }
      }
    }
    .tag-input {
      .tag {
        color: var(--bs-light);
      }
    }
  }

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    font-size: calc(1.25625rem + 0.075vw);
  }

  a {
    color: var(--bs-link-color);
    text-decoration: none;
    &:hover {
      color: var(--bs-link-hover-color);
      text-decoration: underline;
    }
  }

  .animate-typing {
    .dot {
      width: 5px;
      height: 5px;
    }
  }

  .chat-list {
    .animate-typing {
      .dot {
        width: 0.3em;
      }
    }
  }

  .rs-check-item .rs-checkbox-checker > label:before {
    // background-color: var(--bs-body-bg) !important;
    background-color: transparent !important;
  }

  .rs-btn-primary {
    background-color: var(--bs-primary) !important;
  }

  .rs-picker {
    background-color: var(--bs-input-bg) !important;
    border-color: var(--bs-input-border-color) !important;
    .rs-picker-toggle {
      background-color: var(--bs-body-bg) !important;
      &:hover {
        background-color: var(--bs-body-bg) !important;
      }
    }
  }

  .rs-picker-menu {
    background-color: var(--bs-body-bg) !important;
    position: absolute;
    z-index: 9999;
  }

  .rs-tag {
    background-color: var(--bs-dropdown-link-color);
    color: var(--bs-light);
  }

  .rs-picker-default .rs-btn,
  .rs-picker-default .rs-picker-toggle,
  .rs-picker-input .rs-btn,
  .rs-picker-input .rs-picker-toggle {
    // background-color: var(--bs-input-bg) !important;
    border-color: var(--bs-input-border-color) !important;
  }

  .rs-picker-toggle-value,
  .rs-picker-toggle-caret.rs-icon {
    color: var(--bs-link-color) !important;
  }

  .rs-stack {
    // background-color: var(--bs-input-bg) !important;
    background-color: var(--bs-body-bg) !important;
    border-color: var(--bs-input-border-color) !important;
    .rs-calendar:first-child {
      border-color: var(--bs-input-border-color) !important;
    }
    .rs-picker-daterange-header {
      color: var(--bs-link-color) !important;
      border-color: var(--bs-input-border-color) !important;
    }
    .rs-stack-item {
      // background-color: var(--bs-input-bg) !important;
      background-color: transparent !important;
    }
    .rs-btn-link {
      color: var(--bs-link-color) !important;
    }

    .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
      background-color: var(--bs-primary) !important;
    }

    .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
      box-shadow: inset 0 0 0 1px var(--bs-primary) !important;
    }

    .rs-calendar-table-cell-disabled .rs-calendar-table-cell-content,
    .rs-calendar-table-cell-disabled .rs-calendar-table-cell-content:hover,
    .rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-content,
    .rs-calendar-table-cell-un-same-month
      .rs-calendar-table-cell-content:hover {
      color: var(--bs-link-color) !important;
    }

    .rs-picker-toggle-textbox {
      background-color: var(--bs-body-bg) !important;
      border: none;
      &:hover {
        background-color: var(--bs-body-bg) !important;
      }
    }
  }

  .rs-table-hover .rs-table-row:hover .rs-table-cell,
  .rs-table-hover .rs-table-row:hover .rs-table-cell-group,
  .rs-table-row-expanded,
  .rs-table-row-header,
  .rs-table-row-header .rs-table-cell {
    color: var(--bs-body-color) !important;
    border-color: var(--bs-border-color) !important;
    background-color: transparent !important;
  }

  .rs-table-hover .rs-table-row:hover .rs-table-cell,
  .rs-table-hover .rs-table-row:hover .rs-table-cell-group,
  .rs-table-hover .rs-table-row:not(.rs-table-row-header):hover {
    background-color: var(--bs-body-bg) !important;
    background-color: var(--bs-light) !important;
  }

  .rs-table {
    .rs-table-cell,
    .rs-calendar-table-cell-content,
    .rs-table-row .rs-table-row-header {
      border-color: var(--bs-border-color) !important;
      background-color: transparent !important;
      &:hover {
        background-color: var(--bs-body-bg) !important;
        background-color: var(--bs-light) !important;
      }
    }
    .rs-table-loader-wrapper {
      background-color: var(--bs-light) !important;
    }
  }

  .rs-picker-toggle-active, .rs-picker.rs-picker-focused {
    box-shadow: none;
  }

  .apexcharts-canvas {
    .apexcharts-legend {
      .apexcharts-legend-text {
        color: var(--bs-body-color) !important;
      }
    }

    .apexcharts-text,
    .apexcharts-text tspan,
    .apexcharts-title-text {
      color: var(--bs-body-color) !important;
    }

    svg {
      background-color: transparent !important;
    }
  }

  .no-scroll {
    overflow: hidden !important;
    .simplebar-content-wrapper {
      overflow: hidden !important;
    }
  }

  .text-theme {
    --bs-text-opacity: 1;
    color: #7269ef !important;
  }

  .chatInputWrapper .btn:focus {
    outline: 0;
    box-shadow: none;
  }

  .emoji-mart {
    box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
  }

  .emoji-dropdown .dropdown-menu {
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: none;
  }

  .chat-leftsidebar .user-status-box {
    margin-bottom: 16px;
  }

  .chat-guests {
    .img-fluid {
      clip-path: circle(2rem at center);
      height: 100%;
      object-fit: cover;
    }
  }

  @media (max-width: 575.98px) {
    .chatInputWrapper {
      position: absolute;
      bottom: 0;
      width: 100%;
      .chatInput {
        border-radius: 0.4rem 0 0 0.4rem;
      }
      .chat-send {
        border-radius: 0 0.4rem 0.4rem 0;
      }
    }
  }
}

.chat-list {
  li {
    .hover-show {
      // display: none !important;
      @media (max-width: 991.98px) {
        display: block !important;
      }
      position: absolute;
      right: 0;
      top: 20px;
      // z-index: 2;
      a {
        border-color: transparent !important;
        background: transparent !important;
        padding: 15px 5px;
      }
    }
    &:hover {
      .hover-show {
        display: block !important;
      }
    }
  }
}

.error-fill {
  fill: #ef476f !important;
}

table.container td:first-child {
  color: #fb667a;
}

.faux-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  right: 15px;
  top: 35px;
  position: absolute;
}

.faux-logo {
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  // justify-content: center;
  // left: 30px;
  // top: 5px;
  // position: absolute;
  .logo {
    img {
      max-height: 3em;
    }
  }
}

.auth-logo {
  margin-top: 0.5rem !important;
}

.btn.btn-loading:disabled,
.btn.btn-loading.disabled,
fieldset:disabled .btn.btn-loading {
  opacity: inherit;
}

.bg-theme {
  color: #fff;
  background-color: #5b54bf;
  border-color: #564fb3;
}

a.arrow {
  display: flex;
  align-items: center;
  color: #7269ef;
  line-height: 1.5;
  position: relative;
  text-decoration: none;
  transition: all 0.3s ease;
  .arrow_icon {
    position: relative;
    margin-left: 0.5em;
    svg {
      transition: transform 0.3s 0.02s ease;
      margin-right: 1em;
    }
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 55%;
      left: 0;
      width: 0;
      height: 1.3px;
      background: #7269ef;
      transform: translateY(-50%);
      transition: width 0.3s ease;
    }
  }
  &:hover {
    .arrow_icon {
      &::before {
        width: 1em;
      }
      svg {
        transform: translateX(0.75em);
      }
    }
  }
}
span.arrow {
  display: flex;
  align-items: center;
  color: #7269ef;
  line-height: 1.5;
  position: relative;
  text-decoration: none;
  transition: all 0.3s ease;
  .arrow_icon {
    position: relative;
    margin-left: 0.5em;
    svg {
      transition: transform 0.3s 0.02s ease;
      margin-right: 1em;
    }
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 55%;
      left: 0;
      width: 0;
      height: 1.3px;
      background: #7269ef;
      transform: translateY(-50%);
      transition: width 0.3s ease;
    }
  }
  &:hover {
    .arrow_icon {
      &::before {
        width: 1em;
      }
      svg {
        transform: translateX(0.75em);
      }
    }
  }
}

.avatar-title {
  user-select: none;
}

.card-textarea {
  height: 300px;
  text-align: left;
  border: 0;
  padding: 20px;
  border-radius: 5px 5px 0 0;
  outline: 0;
  line-height: 20px;
  resize: none;
}

.card-textarea-btn {
  border-radius: 0 0 5px 5px;
  height: 42px;
  border: 0;
  cursor: pointer;
  outline: 0;
  font-weight: 700;
}

.quick-buttons h5 {
  font-size: 0.6em !important;
}

.trueview-modal {
  background: rgba(114, 105, 239, 0.8);
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.5s ease;
  width: 100%;
  height: 100%;

  .content {
    color: transparent;
    position: absolute;
    transition: color 0.5s ease;

    .cursor {
      display: none;
    }
  }

  button {
    cursor: pointer;
    height: 0;
    opacity: 0;
    width: 0;

    &:focus {
      outline: none;
    }
  }

  &.modal-active {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    transition: opacity 0.5s ease;
    opacity: 1;
    z-index: 999;
    @media (max-width: 575.98px) {
      // z-index: 999;
    }

    button {
      height: 40px;
      opacity: 1;
      position: fixed;
      right: 20px;
      top: 20px;
      z-index: 1;
      -webkit-appearance: none;
      width: 40px;
      outline: none !important;
      border: none;
    }

    .content {
      transition: color 0.5s ease 0.5s;
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;
      // height: 100%;
      // z-index: 999;
      // overflow: scroll;

      .cursor {
        display: block;
        position: fixed;
        top: 50%;
        left: 50%;
        background: #e7e7e7;
        background: rgba(114, 105, 239, 0.2);
        width: 20px;
        height: 20px;
        box-shadow: 0px 1px 5px 5px rgba(114, 105, 239, 0.5);
        border-radius: 50%;
        z-index: 999;
        pointer-events: none;
      }

      .same-orientation {
        -ms-zoom: 0.85 !important;
        -moz-transform: scale(0.85) !important;
        -o-transform: scale(0.85) !important;
        -webkit-transform: scale(0.85) !important;
        transform: scale(0.85) !important;
      }

      &:hover {
        cursor: none !important;
      }

      &.desktop {
        .cursor {
          width: 15px;
          height: 15px;
          content: url("../svg/cursor.svg");
          background: transparent;
          box-shadow: none;
          border-radius: 0;
        }
      }

      @media (min-width: 768px) {
        // margin-left: 75px;
      }
    }
  }
}

.trueview-panel {
  background: rgba(114, 105, 239, 0.5);
  z-index: 999;
  width: 18px;
  overflow: hidden;
  position: fixed;
  top: 50%;
  right: 0;
  padding: 0 5px;
  border-radius: 20px 0 0 20px;
  transition: 0.25s;
  transition-timing-function: ease-out;

  @media (max-width: 575.98px) {
    width: 50px;
  }

  &:hover {
    width: 50px;
  }
}

.accordion-header.transparent button {
  background: transparent !important;
  border: none;
}

.card {
  @each $color, $value in $theme-colors {
    .border-left-#{$color},
    &.border-left-#{$color} {
      border-left: 4px solid $value !important;
    }

    .border-right-#{$color},
    &.border-right-#{$color} {
      border-right: 4px solid $value !important;
    }

    .border-top-#{$color},
    &.border-top-#{$color} {
      border-top: 4px solid $value !important;
    }

    .border-bottom-#{$color},
    &.border-bottom-#{$color} {
      border-bottom: 4px solid $value !important;
    }
  }

  .text-title {
    color: #8898aa;
    font-weight: 500;
    font-size: 14px;
  }

  .text-amount {
    font-weight: 600;
  }

  .icon-shape {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
  }

  @media (max-width: 360px) {
    .icon-shape {
      display: none !important;
    }
  }
}

.action-selection {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  // margin-right: -15px;
  // margin-left: -15px;
  gap: 5px;
  --bs-gutter-x: 24px;
  --bs-gutter-y: 0;
  .action-cards {
    max-width: 75px;
  }
}

.action-cards {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: auto;
  .action {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    .link {
      min-width: 150px;
      font-size: 0.9rem;
      font-weight: 500;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 5px;
      padding: 20px;
      i {
        font-size: 1.5em;
      }
      svg {
        width: 20%;
        stroke: var(--bs-link-color);
        fill: var(--bs-link-color);
        // margin-bottom: 10px;
      }
    }
  }
}

.accordion {
  .accordion-item {
    .accordion-header {
      button {
        color: var(--bs-body-color);
      }
    }
  }
}

.tox
  .tox-toolbar-overlord
  .tox-toolbar:not(.tox-toolbar--scrolling):first-child,
.tox .tox-toolbar-overlord .tox-toolbar__primary,
.tox:not(.tox-tinymce-inline) .tox-editor-header {
  border-bottom: 0 !important;
  border: 0 !important;
  background-color: var(--bs-body-bg) !important;
  border-color: transparent !important;
  padding: 0 !important;
}

.tox-tinymce {
  background-color: var(--bs-body-bg) !important;
  border-color: var(--bs-border-color) !important;
}

.tox .tox-edit-area__iframe body {
  border: 0 !important;
  background-color: var(--bs-body-bg) !important;
  border-color: var(--bs-border-color) !important;
}

@media (max-width: 768px) {
  .action-selection {
    justify-content: space-between;
    .action-cards {
      max-width: 70px;
      flex: 1 0 calc(25% - 10px);
    }
  }
}

@media (max-width: 991.98px) {
  .user-entities {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    // height: 100%;
    height: calc(100% - 60px);
    overflow-y: scroll;
    visibility: hidden;
    transform: translateX(100%);
    // z-index: 99;
  }

  .user-entities.user-entities-show {
    visibility: visible;
    transform: translateX(0);
  }

  .chatInputWrapper {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
}

.spinning {
  animation: spin-animation 0.5s infinite;
  display: inline-block;
}

.spinning-slow {
  animation: spin-animation 2.5s infinite;
  display: inline-block;
}

.border-dashed {
  border-style: dashed !important;
}

.border-dotted {
  border-style: dotted !important;
}

button.btn-circle {
  border-radius: 40px;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  padding: initial;
}

.switch-wrapper {
  position: relative;
  margin-top: -20px;

  .switch {
    display: inline-block;
    width: 55px;
    height: 25px;
    margin: 4px;
    transform: translateY(50%);
    position: relative;

    .slider {
      position: absolute;
      content: "";
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 30px;
      box-shadow: 0 0 0 2px #777, 0 0 4px #777;
      cursor: pointer;
      border: 4px solid transparent;
      overflow: hidden;
      transition: 0.2s;

      &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        background-color: #777777;
        border-radius: 30px;
        transform: translateX(-30px);
        transition: 0.2s;
      }

      &.disabled {
        box-shadow: 0 0 0 2px #777777, 0 0 8px #777777;
        &::before {
          background-color: #777777;
        }
      }
    }
  }

  .information {
    margin-top: 10px;
    font-size: 12px;
  }

  input {
    display: none !important;
  }

  input:checked + .slider::before {
    transform: translateX(30px);
    background-color: #7269ef;
  }

  input:checked + .slider {
    box-shadow: 0 0 0 2px #7269ef, 0 0 8px #7269ef;
  }
}

.calendar {
  // display: block;
  // margin: 0 auto;
  // margin-top: 2.5em;
  // max-width: 50em;
  display: block;
  position: relative;
  width: 100%;
  background: var(--bs-body-bg);
  border: 1px solid var(--bs-border-color);
  user-select: none;
  .header {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 115%;
    padding: 1.5em 0 !important;
    border-bottom: 1px solid var(--bs-border-color);
    .col {
      &:first-of-type {
        .icons {
          // margin-left: 1em;
        }
      }
      &:last-of-type {
        .icons {
          // margin-right: 1em;
          justify-content: flex-end;
        }
      }
    }
    .icons {
      display: flex;
      .icon {
        cursor: pointer;
        transition: 0.15s ease-out;
        font-size: x-large;
        &:hover {
          // transform: scale(1.1);
          transition: 0.25s ease-out;
          color: var(--bs-primary);
        }
      }
    }
  }
  .row {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
  .row-middle {
    align-items: center;
  }
  .col {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-start {
    justify-content: flex-start;
    text-align: left;
  }

  .col-center {
    justify-content: center;
    text-align: center;
    align-items: center;
    display: flex;
  }

  .col-end {
    justify-content: flex-end;
    text-align: right;
  }
  .days {
    text-transform: uppercase;
    font-weight: 400;
    color: var(--bs-body-color);
    font-size: 70%;
    padding: 0.75em 0;
    border-bottom: 1px solid var(--bs-border-color);
  }
  .body {
    .cell {
      position: relative;
      // height: 5em;
      height: calc(100vh / 6);
      border-right: 1px solid var(--bs-border-color);
      overflow: hidden;
      background: var(--bs-body-bg);
      transition: 0.25s ease-out;
      cursor: pointer;
      user-select: none;
      &.disabled {
        cursor: not-allowed;
        &:hover {
          background-color: var(--bs-gray-300); 
        }
      }
      &:hover {
        background-color: rgba(114, 105, 239, 0.25)
      }
      .number {
        position: absolute;
        font-size: 82.5%;
        line-height: 1;
        top: 0.75em;
        right: 0.75em;
        font-weight: 700;
      }
      .bg {
        font-weight: 700;
        line-height: 1;
        color: var(--bs-primary);
        opacity: 0;
        font-size: 8em;
        position: absolute;
        top: -0.2em;
        right: -0.05em;
        transition: 0.25s ease-out;
        letter-spacing: -0.07em;
      }
      &:last-child {
        border-right: none;
      }
    }
    .booked {
      border-left: 10px solid transparent;
      // border-color: rgba(114, 105, 239, 0.25);
      border-left-color: rgba(6, 214, 160, 0.25);
      border-image-slice: 1;
      .bg {
        opacity: 0.05;
        transition: 0.5s ease-in;
        color: var(--bs-success);
      }
      &:hover {
        background-color: transparent;
      }
    }
    .selected {
      border-left: 10px solid transparent;
      border-image: linear-gradient(45deg, var(--bs-primary) 0%, rgba(114, 105, 239, 0.25) 40%);
      border-left-color: initial;
      border-image-slice: 1;
      .bg {
        opacity: 0.1;
        transition: 0.5s ease-in;
      }
      &.booked {
        border-image: initial;
        border-left-color: rgba(6, 214, 160, 0.25);
      }
      &:hover {
        background-color: var(--bs-body-bg);
      }
    }
    .disabled {
      color: var(--bs-gray-500);
      // pointer-events: none;
    }
    .row {
      border-bottom: 1px solid var(--bs-border-color);
      &:last-child {
        border-bottom: none;
      }
    }
    .col {
      flex-grow: 0;
      flex-basis: calc(100% / 7);
      width: calc(100% / 7);
    }
  }
}

.boards {
  display: flex;
  width: 100%;

  .board {
    border: 1px solid var(--bs-border-color);
    border-radius: 4px;
    margin: 0 16px;
    flex: 1;

    h3 {
      padding: 16px;
      margin: 0;
    }

    .dropzone {
      padding: 0 16px 16px;
      // min-width: 282px;
      min-height: 200px;
    }

    .card {
      background-color: var(--bs-body-bg);
      padding: 16px;
      // box-shadow: 0 2px 2px -1px var(--bs-btn-bg);
      width: 100%;
      margin: 0 0 10px 0;
      border-radius: 4px;
      font-weight: 600;
      font-size: 18px;
      -webkit-overflow-scrolling: touch;
      scroll-behavior: smooth;
      transition: 400ms;
      cursor: grab;
    }

    .is-dragging {
      cursor: grab;
      opacity: 0.3;
    }

    .status {
      width: 30px;
      height: 8px;
      background: var(--bs-primary-bg);
      margin-bottom: 10px;
      border-radius: 8px;
      color: var(--bs-body-color)
    }

    .field {
      font-size: 50%;
      // color: var(--bs-body-color);
      margin-bottom: 10px;
    }

    .dropzone {
      transition: 400ms;
    }

    .over {
      opacity: 0.6;
    }
  }
}

.user-profile-sidebar{
  transition: flex 0.3s ease;
  width: 100%;
  flex: 1;
}

@media screen and (max-width: 992px) {
  .boards {
    margin: 0 auto;
    justify-content: center;

    .board {
      padding: 20px 0;
      margin: 0px;
      margin-bottom: 20px;

      h1 {
        text-align: center;
      }
    }
  }
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}
