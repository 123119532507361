//
// _dropdown.scss
//

.dropdown-menu {
  box-shadow: $box-shadow-lg;
  animation-name: DropDownSlide;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  position: absolute;
  z-index: 1000;

  &.show {
    top: 100% !important;
  }

  &.dropdown-megamenu {
    padding: 20px;
    left: 0 !important;
    right: 0 !important;
  }

  &[data-popper-placement="top-start"] {
    animation-name: DropDownSlideDown;
  }
}

.dropdown-menu[data-popper-placement^=right],
.dropdown-menu[data-popper-placement^=top],
.dropdown-menu[data-popper-placement^=left] {
  top: auto !important;
  animation: none !important;
}

// @keyframes DropDownSlide {
//   100% {
//     margin-top: -1px;
//   }
//   0% {
//     margin-top: 8px;
//   }
// }

@keyframes DropDownSlide {
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }

  0% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px)
  }
}

@keyframes DropDownSlideDown {
  100% {
    margin-bottom: 0;
  }
  0% {
    margin-bottom: 8px;
  }
}

.dropdown-toggle {
  @include caret(up);
}

.dropdown-menu-end[style] {
  right: 0 !important;
  left: auto !important;
}

@media (min-width: 600px) {
  .dropdown-menu-lg {
    width: 320px;
  }

  .dropdown-menu-md {
    width: 240px;
  }
}

.dropdown-divider {
  border-top-color: $border-color;
}

.language-switcher.dropdown .dropdown-content {
  display: none;
  position: absolute;

  right: 0.1em;
}

.language-switcher.dropdown.dropdown--open .dropdown-content {
  display: inline-block;
  z-index: 5;
}

.language-switcher.dropdown-arrow {
  transform: rotate(0deg);
}

.language-switcher.dropdown.dropdown--open .dropdown-arrow {
  transform: rotate(180deg);
}

.language-switcher {
  user-select: none;
  display: inline-block;
  position: relative;
}

.language-switcher__flag {
  width: 18px;
  height: 18px;
  // margin-right: 10px;
}

.language-switcher__flag_mini {
    width: 18px;
    height: 12px;
}

.language-switcher__content {
  border-radius: 7px;
  background-color: rgba(255, 255, 255, 0.7);
  display: inline-block;
  padding: 10px 0;
}

.language-switcher__content-item {
  height: 32px;
  padding: 0 18px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  transition: background-color 0.125s;

  &:hover{
    background-color: #f0eff5
  }

  &.active{
    background-color: #e6ebf5;
  }
}

.language-switcher__handle {
  // height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.language-switcher__handle .language-switcher__flag {
  margin-right: 5px;
}
