.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;

  .logo {
    height: 60px;
  }

  .loading-bar {
    width: 130px;
    height: 2px;
    background: #cfcfcf;
    margin-top: 20px;
    position: relative;
    overflow: hidden;
  }

  .loading-bar::before {
    content: '';
    // width: 68px;
    height: 2px;
    background: rgb(109, 88, 255);
    position: absolute;
    // left: -34px;
    // animation: progress-bar 1.5s infinite ease;
  }

  .progress-div {
    background-color: rgba(114, 105, 239, 0.25);
    border-radius: 0.5rem;
    margin: 10px auto;
  }

  .progress {
    background-color: rgb(109, 88, 255);
    height: 3.5px;
    border-radius: 1rem;
    transition: 1s ease;
    // transition-delay: 0.5s;
    // transition-delay: 0.2s;
  }
}

.loader{
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader > span{
  display: inline-block;
  background-color: purple;
  width: 0px;
  height: 0px;
  border-radius: 50%;
  margin: 0 8px;
  transform: translate3d(0);
  animation: bounce 0.6s infinite alternate;
}

.loader svg path,
.loader svg rect {
  fill: rgb(109, 88, 255);
}

.loader > span:nth-child(2){
  background-color: palevioletred;
  animation-delay: 0.2s;
}

.loader > span:nth-child(3){
  animation-delay: 0.4s;
}

.loading {
  &:before,&:after{
    position:absolute;
    content:'';
  }

  &:before{
    top:0;
    left:0;
    width:100%;
    height:100%;
    // background: rgba(0,0,0,0.25);
  }

  &:after{
    top: 50%;
    left: 50%;
    margin-top: -25px;
    margin-left: -25px;
    width: 50px;
    height: 50px;
    border: 2px solid rgba(255,255,255,0.25);
    border-top-color: white;
    border-radius: 100%;
    animation: spin .375s infinite linear;
  }
}

@keyframes bounce{
  to{
    width: 16px;
    height: 16px;
    transform: translate3d(0,-16px,0);
  }
}

@keyframes spin {
  100%{
    // @include transform(rotate(360deg));
    transform: (rotate(360deg));
  }
}

@keyframes progress-bar {
  50% {
    left: 96px;
  }
}
