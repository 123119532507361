//
// Google font - Public Sans
//

@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;500;600&display=swap');

@font-face {
    font-family: 'Cabrito Contrast';
    src: url('../../../../assets/fonts/CabritoContrast/CabritoContrast-Bold.woff2') format('woff2'),
        url('../../../../assets/fonts/CabritoContrast/CabritoContrast-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Cabrito Contrast';
    src: url('../../../../assets/fonts/CabritoContrast/CabritoContrast-Medium.woff2') format('woff2'),
        url('../../../../assets/fonts/CabritoContrast/CabritoContrast-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Cabrito Contrast';
    src: url('../../../../assets/fonts/CabritoContrast/CabritoContrast-Black.woff2') format('woff2'),
        url('../../../../assets/fonts/CabritoContrast/CabritoContrast-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Cabrito Contrast';
    src:  url('../../../../assets/fonts/CabritoContrast/CabritoContrast-Light.woff2') format('woff2'),
        url('../../../../assets/fonts/CabritoContrast/CabritoContrast-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Cabrito Contrast';
    src: url('../../../../assets/fonts/CabritoContrast/CabritoContrast-DemiBoldItalic.woff2') format('woff2'),
        url('../../../../assets/fonts/CabritoContrast/CabritoContrast-DemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Cabrito Contrast';
    src: url('../../../../assets/fonts/CabritoContrast/CabritoContrast-ThinItalic.woff2') format('woff2'),
        url('../../../../assets/fonts/CabritoContrast/CabritoContrast-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Cabrito Contrast';
    src: url('../../../../assets/fonts/CabritoContrast/CabritoContrast-Regular.woff2') format('woff2'),
        url('../../../../assets/fonts/CabritoContrast/CabritoContrast-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Cabrito Contrast';
    src: url('../../../../assets/fonts/CabritoContrast/CabritoContrast-MediumItalic.woff2') format('woff2'),
        url('../../../../assets/fonts/CabritoContrast/CabritoContrast-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Cabrito Contrast';
    src: url('../../../../assets/fonts/CabritoContrast/CabritoContrast-BoldItalic.woff2') format('woff2'),
        url('../../../../assets/fonts/CabritoContrast/CabritoContrast-BoldItalic.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Cabrito Contrast';
    src: url('../../../../assets/fonts/CabritoContrast/CabritoContrast-DemiBold.woff2') format('woff2'),
        url('../../../../assets/fonts/CabritoContrast/CabritoContrast-DemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Cabrito Contrast';
    src: url('../../../../assets/fonts/CabritoContrast/CabritoContrast-LightItalic.woff2') format('woff2'),
        url('../../../../assets/fonts/CabritoContrast/CabritoContrast-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Cabrito Contrast';
    src: url('../../../../assets/fonts/CabritoContrast/CabritoContrast-BlackItalic.woff2') format('woff2'),
        url('../../../../assets/fonts/CabritoContrast/CabritoContrast-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Cabrito Contrast';
    src: url('../../../../assets/fonts/CabritoContrast/CabritoContrast-RegularItalic.woff2') format('woff2'),
        url('../../../../assets/fonts/CabritoContrast/CabritoContrast-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Cabrito Contrast';
    src: url('../../../../assets/fonts/CabritoContrast/CabritoContrast-Thin.woff2') format('woff2'),
        url('../../../../assets/fonts/CabritoContrast/CabritoContrast-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}
