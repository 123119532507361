.play-pause-button {
  --play: #6D58FF;
  --play-shadow: #{rgba(#6D58FF, .24)};
  --pause: #495057; //#2B3044;
  --pause-shadow: #{rgba(#2B3044, .24)};
  --color: #fff;
  --icon: var(--color);
  margin: -10px auto 0;
  margin: 0px auto;
  // line-height: 20px;
  font-size: 14px;
  width: 40px;
  height: 37.5px;
  // padding: 11px 12px 11px 36px;
  border-radius: 50%;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  backface-visibility: hidden;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  transform: translateY(var(--y, 0)) translateZ(0);
  color: var(--color);
  // box-shadow: 0 var(--shadow-y, 6px) var(--shadow-b, 16px) var(--shadow, var(--pause-shadow));
  background: radial-gradient(circle, var(--play) 0%, var(--play) 50%, var(--pause) 50.5%, var(--pause) 100%);
  background-size: 400% 400%;
  background-position: 0 0;
  transition: background 0.3s, box-shadow 0.3s, transform 0.3s;

  &:hover {
    // --y: -1px;
    --shadow-y: 8px;
    --shadow-b: 20px;
  }

  &:active {
    // --y: 1px;
    --shadow-y: 4px;
    --shadow-b: 12px;
  }

  &:after,
  &:before {
    content: '';
    background: var(--icon);
    width: var(--width, 16px);
    height: 12px;
    position: absolute;
    left: 15px;
    top: 13px;
    backface-visibility: hidden;
    transform-origin: 50% 100%;
    transform: translateX(var(--x, 0)) translateZ(0);
    -webkit-clip-path: polygon(0 0, 3px 0, 3px 12px, 0 12px);
    clip-path: polygon(0 0, 3px 0, 3px 12px, 0 12px);
    transition: clip-path 0.25s ease;
  }

  &:after {
    --width: 3px;
    --x: 6px;
  }

  i {
    display: block;
    font-weight: bold;
    font-style: normal;
    backface-visibility: hidden;
    opacity: var(--o, 1);
    transform: translateX(var(--x, 0));
    transition: transform 0.6s, opacity 0.6s;

    &:nth-child(2) {
      --o: 0;
      --x: 0;
    }

    &:nth-child(3) {
      --x: -50%;
    }

    &:nth-child(4) {
      --o: 0;
    }

    &:last-child {
      --x: -50%;
    }
  }

  &.playing {
    &:after {
      display: block;
    }
  }

  &.paused {
    // --shadow: var(--play-shadow);
    animation: var(--name, background-paused) 0.8s ease forwards;

    i {
      &:first-child {
        --x: 40%;
      }

      &:nth-child(2) {
        --o: 1;
        --x: 100%;
      }

      &:nth-child(3) {
        --x: 50%;
      }

      &:nth-child(4) {
        --o: 1;
        --x: 50%;
      }

      &:last-child {
        --x: 0;
        --o: 0;
      }
    }

    &:before {
      -webkit-clip-path: polygon(0 0, 11px 6px, 11px 6px, 0 12px);
      clip-path: polygon(0 0, 11px 6px, 11px 6px, 0 12px);
      transition-delay: 0.2s;
    }

    &:after {
      // display: none;
      animation: to-play 0.2s ease forwards;
    }

    &.playing {
      // --shadow: var(--pause-shadow);
      --name: background-playing;

      &:before {
        -webkit-clip-path: polygon(0 0, 3px 0, 3px 12px, 0 12px);
        clip-path: polygon(0 0, 3px 0, 3px 12px, 0 12px);
        transition-delay: 0s;
      }

      &:after {
        animation: to-pause 1.3s ease forwards;
      }

      i {
        &:first-child {
          --x: 0;
        }

        &:nth-child(2) {
          --o: 0;
          --x: 0;
        }

        &:nth-child(3) {
          --x: -50%;
          --o: 1;
        }

        &:nth-child(4) {
          --o: 0;
          --x: 0;
        }

        &:last-child {
          --x: -50%;
          --o: 1;
        }
      }
    }
  }
}

wave {
  cursor: pointer !important;
  &:first-of-type {
    // max-height: 4em;
    // max-width: calc(100% - 50px);
    margin: 0 auto;
  }
}

@keyframes to-play {
  15% {
    transform: translateX(6px) scaleY(1.1);
  }

  30% {
    transform: translateX(6px) scaleY(.9);
  }

  45% {
    transform: translateX(6px) scaleY(1.15);
    -webkit-clip-path: polygon(0 0, 3px 0, 3px 12px, 0 12px);
    clip-path: polygon(0 0, 3px 0, 3px 12px, 0 12px);
    transform-origin: 50% 100%;
  }

  100%,
  60% {
    -webkit-clip-path: polygon(0 9px, 3px 9px, 3px 12px, 0 12px);
    clip-path: polygon(0 9px, 3px 9px, 3px 12px, 0 12px);
    transform-origin: 50% 10.5px;
  }

  60% {
    transform: translateX(6px);
  }

  99% {
    transform: translateX(0) rotate(-270deg);
  }

  100% {
    transform: translateX(0) rotate(-270deg) scale(0);
  }
}
@keyframes to-pause {
  0%,
  50% {
    -webkit-clip-path: polygon(0 9px, 3px 9px, 3px 12px, 0 12px);
    clip-path: polygon(0 9px, 3px 9px, 3px 12px, 0 12px);
    transform-origin: 50% 10.5px;
  }

  0%,
  39% {
    transform: translateX(0) rotate(-270deg) scale(0);
  }

  40% {
    transform: translateX(0) rotate(-270deg);
  }

  50% {
    transform: translateX(6px) rotate(0deg);
  }

  100%,
  60% {
    transform: translateX(6px);
    -webkit-clip-path: polygon(0 0, 3px 0, 3px 12px, 0 12px);
    clip-path: polygon(0 0, 3px 0, 3px 12px, 0 12px);
    transform-origin: 50% 100%;
  }

  70% {
    transform: translateX(6px) scaleY(1.15);
  }

  80% {
    transform: translateX(6px) scaleY(.9);
  }

  90% {
    transform: translateX(6px) scaleY(1.05);
  }

  100% {
    transform: translateX(6px);
  }
}
@keyframes background-paused {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 50% 50%;
  }
}
@keyframes background-playing {
  from {
    background-position: 50% 50%;
  }

  to {
    background-position: 100% 100%;
  }
}
