// Components

.preview-pane {
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 5;
  top: 0;
  left: 0;
  display: inline-block;
  // ADDITION
  height: 100%;
  overflow-y: scroll;

  .video-wrap {
    .progressTime {
      display: none;
      position: relative;
      bottom: auto;
      padding: 0;
    }
  }
}

.preview-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // z-index: 1;
  text-align: center;
  backface-visibility: hidden;
  display: inline-block;
  padding: 10px;

  .close-wrapper {
    position: fixed;
    right: 15px;
    text-align: right !important;
    z-index: 1;

    .close-btn {
      height: 40px;
      width: 40px;
      line-height: 40px;
      box-shadow: none;
      padding: 0;
      font-size: 25px;
    }
  }
}

body[data-theme="light"] {
  .preview-background {
    background-color: #FFFFFF;

    .close-btn {
      color: #7a7f9a;
    }
  }
}

body[data-theme="dark"] {
  .preview-background {
    background-color: #262e35;

    .close-btn {
      color: #abb4d2;
    }
  }
}
