.replyCard {
  left: 0;
  right: 0;
  overflow: hidden;
  opacity: 0;
  bottom: 0;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  height: 0;
  @media screen and (max-width: 600px) {
    position: relative;
  }

  @media (min-width:900px) {
    position: absolute;
  }

  &.show {
    -webkit-transform: translateY(-92px);
    transform: translateY(-92px);
    opacity: 1;
    height: auto;
  }

  .reply-block {
    padding: 12px 20px;
    font-size: 14px;
    margin-bottom: 8px;
    text-align: left;
    border-radius: 4px;
    // background-color: rgba(var(--bs-primary-rgb),.1);
    border-left: 2px solid rgba(var(--bs-primary-rgb),1);
  }
}
