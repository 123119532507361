.tag-list {
  // width: 30rem;
  // max-width: 90vw;
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  gap: 1rem 0;
  position: relative;
  padding: 0.5rem 0;
  overflow: hidden;

  .tag {
    display: flex;
    align-items: center;
    gap: 0 0.2rem;
    color: var(--bs-body-color);
    font-size: 0.9rem;
    background-color: var(--bs-bg-color);
    border: 1px solid var(--bs-border-color);
    border-radius: 0.4rem;
    padding: 0.25rem 0.75rem;
    margin-right: 1rem; // Must use margin-right instead of gap for the loop to be smooth
    cursor: pointer;
    user-select: none;

    &:hover {
      background-color: var(--bs-bg-color);
      border: 1px solid var(--bs-border-color);
    }

    span {
      font-size: 1.2rem;
      color: #64748b;
    }

    a {
      text-decoration: none;
      user-select: none;
      color: var(--bs-body-color);
      &:hover,
      :focus,
      :visited {
        text-decoration: none;
        color: var(--bs-body-color);
      }
    }
  }
}

.tag-input {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
  padding: 0.3rem 1rem;
  margin: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: var(--bs-input-bg);
  background-clip: padding-box;
  border: 1px solid var(--bs-input-border-color);
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  input {
    flex: 1;
    border: none;
    height: 30px;
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    background-color: var(--bs-input-bg);
    background-clip: padding-box;
    border: none;
    appearance: none;
    outline: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:focus {
      outline: transparent;
    }
  }

  .tags {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    list-style: none;
    gap: 5px;

    .tag {
      width: auto;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 8px;
      list-style: none;
      border-radius: 6px;
      background: var(--bs-primary);

      .tag-title {
        
      }

      .tag-close-icon {
        display: block;
        width: 16px;
        height: 16px;
        line-height: 16px;
        text-align: center;
        font-size: 14px;
        margin-left: 8px;
        cursor: pointer;
      }
    }
  }
}
