.timeline {
  position: relative;
  padding-left: 4rem;
  margin: 0 0 0 15px;
  // color: white;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    background: #27293d;
  }

  .timeline-container {
    position: relative;
    margin-bottom: 2.5rem;

    .timeline-icon {
      position: absolute;
      left: -78px;
      top: 4px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      text-align: center;
      // font-size: 2rem;
      background: #4f537b;

      i {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .timeline-body {
      background: #27293d;
      border-radius: 3px;
      padding: 10px 15px 5px;
      box-shadow: 1px 3px 9px rgba(0, 0, 0, 0.1);
      max-width: 95%;

      .timeline-title {
        // margin-bottom: 1.4rem;
        .badge {
          background: #4f537b;
          padding: 4px 8px;
          border-radius: 3px;
          font-size: 12px;
          font-weight: bold;
        }
      }

      .timeline-content {
        margin-bottom: .5rem;
      }

      .timeline-subtitle {
        font-weight: 300;
        font-style: italic;
        opacity: 0.4;
        // margin-top: 16px;
        margin: 0px;
        font-size: 11px;
      }

      &:before {
        content: "";
        background: inherit;
        width: 20px;
        height: 20px;
        display: block;
        position: absolute;
        left: -10px;
        transform: rotate(45deg);
        border-radius: 0 0 0 2px;
      }
    }
  }

  @each $color,
  $value in $theme-colors {
    .timeline-container.#{$color} {
      .badge,
      .timeline-icon {
        background-color: rgba(($value), 1) !important;
      }
    }
  }
}

body {
  &[data-theme="dark"] {
    .timeline {
      &:before {
      }
      .timeline-body {
      }
    }
  }
  &[data-theme="light"] {
    .timeline {
      &:before {
        background-color: #f0eff5;
      }

      .timeline-container {
        .timeline-icon {
          color: #ffffff;
        }
      }
      .timeline-body {
        background: #ffffff;
      }
    }
  }
}
