.gallery-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  max-height: 70%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  // margin: 20px auto 0;
  .audio-item {
    flex-basis: 100%;
    width: 100%;
    justify-content: center;
    margin: 3rem 0.5rem 0 0.5rem;
  }

  .item {
    flex: 1;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: none;
    transition: flex 0.5s ease;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    img {
      flex-shrink: 0;
      object-fit: cover;
      min-width: 100%;
      min-height: 100%;
      width: 100%;
      height: auto;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    &:hover {
      flex: 7;
    }
  }
  .c-slider {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    width: 770px;

    .c-slide {
      font-weight: normal;
      margin-right: 20px;
      background: #FFFFFF;
      border-radius: 3px;
      border-left: 10px solid #FCEDB7;
      padding: 20px;
      box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.15);

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      flex: 1;
      overflow: hidden;
    }
  }
}

.gallery-list {
  height: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);

  li {
    list-style-type: none;
    width: 200px;
    height: 200px;
    opacity: .25;
    position: absolute;
    left: 50%;
    margin-left: -100px;
    border-radius: 2px;
    background: #9C89B8;
    transition: transform 1s, opacity 1s;
  }

  .gallery-act {
    opacity: 1;
  }

  .prev,
  .next {
    cursor: pointer;
  }

  .prev {
    transform: translateX(-220px) scale(.85);
  }

  .next {
    transform: translateX(220px) scale(.85);
  }

  .hide {
    transform: translateX(-420px) scale(.85);
  }

  .new-next {
    transform: translateX(420px) scale(.85);
  }

  .hide,
  .new-next {
    opacity: 0;
    transition: opacity .5s, transform .5s;
  }
}

.gallery-swipe {
  width: 270px;
  height: 200px;
  position: absolute;
  background-color: green;
  border-radius: 2px;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  opacity: 0;
}

.gallery-nav {
  margin: 35px auto 0;
  padding: 0 10px;
}
