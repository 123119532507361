// Objects

.o-header {
  text-align: center;
}

.o-header__subtitle,
.o-header__title {
  margin-bottom: 0;
  margin-top: 0;
  font-weight: normal;
}

.o-header__title {
  font-size: 1.8rem;
}

.o-header__subtitle {
  font-size: 1rem;
}

.o-icon {
  height: 1em;
  width: 1em;
  display: inline-block;
  fill: currentColor;
}
// Components

.c-dropzone {
  position: absolute;
  width: 100%;
  height: 100%;
  // z-index: 1;
  top: 0;
  left: 0;
  display: inline-block;
  // &:hover{
  //   .c-dropzone__background {
  //     transition: all 1s ease;
  //     background-color: rgba(154, 105, 239, 0.80);
  //     border-color: rgba(114, 105, 239, 0.25);
  //   }
  //   * {
  //     color: #eeeeee;
  //   }
  // }
}

.c-dropzone__icon {
  font-size: 300%;
  // color: #EEE
  padding: 2rem 1rem 0;
}

.c-dropzone__background {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  text-align: center;
  // transition: all 1s ease;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: rgba(114, 105, 239, 0.25);
  border-width: 4px;
  border-style: dashed;
  flex-direction: column;
}

body[data-theme="light"] {
  .c-dropzone__background {
    background-color: #FFFFFF;
  }
}

body[data-theme="dark"] {
  .c-dropzone__background {
    background-color: #262e35;
  }
}
