.video-wrap {
  // padding-top: 6rem;
  // max-width: 1000px;
  // margin: auto;
  // height: 100%;

  video {
    // background-color: rgba(114, 105, 239, 0.25) !important;
    // object-fit: cover;
    width: 100%;
    display: block;
    max-height: 40vh;
    border-radius: 8px 8px 0 0;
    // box-shadow: 0 1.6px 1.9px rgba(0, 0, 0, 0.07), 0 3.9px 4.5px rgba(0, 0, 0, 0.05), 0 7.3px 8.5px rgba(0, 0, 0, 0.042), 0 13px 15.2px rgba(0, 0, 0, 0.035), 0 24.2px 28.4px rgba(0, 0, 0, 0.028), 0 58px 68px rgba(0, 0, 0, 0.02);
  }

  .progressTime {
    // position: relative;
    // margin: -20px auto;
    // padding: 0px 4px;

    position: absolute;
    width: 100%;
    right: 0;
    padding: 0px 25px;
    bottom: 40px;
  }

  .progressBar {
    position: relative;
    // border-radius: 30px;
    height: 4px;
    background-color: rgba(114, 105, 239, 0.25) !important;
    margin: -3.1px 0 0;
    overflow: hidden;

    .timeBar {
      position: absolute;
      height: 3px;
      background-color: #7269ef;
      top: 0;
      left: 0;
    }
  }

  .progressTime,
  span {
    pointer-events: none;
    user-select: none;
    color: #7269ef;
    font-size: 0.75rem;
    text-shadow: 0.2px 0.2px #cccccc;
  }
}
