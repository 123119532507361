$primary-text-color: #000;
$secondary-text-color: #A1A1A4;
$theme-color: #5b54bf;

.toggler {
  // color: $secondary-text-color;
  font-size: 1.25em;
  // margin-left: 8px;
  text-align: center;
  cursor: pointer;

  &.active {
    color: #fff;
    background-color: #5b54bf;
    border-color: #564fb3;
  }
}

.card-lists {
  list-style: none;
  margin: 10px 0 0;
  padding: 0;

  &.grid {
    display: flex !important;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 5px;
    align-items: center;
  }
}

%card-style {
  display: block;
  // padding: 20px;
  border-radius: 2px;
  // box-shadow: 0 2px 1px rgba(170,170,170,0.25);
}

.card-list-item {
  @extend %card-style;

  .plus-card {
    border-radius: 4px;
    border-style: dashed !important;
    color: gray;
  }
}

.card-list-name {
  font-weight: 400;
}

.list {
  .card-list-name {
    font-size: 16px;
  }

  .card-list-item {
    position: relative;
    padding: 0;
    font-size: 14px;
    line-height: 40px;
    margin-top: 10px;

    .pull-right {
      // position: absolute;
      right: 0;
      top: 0;
    }
    @media screen and (max-width: 800px) {
      .stage:not(.active) {
        display: none;
      }
    }
    @media screen and (max-width: 700px) {
      .card-list-progress-bg {
        display: none;
      }
    }
    @media screen and (max-width: 600px) {
      .pull-right {
        position: static;
        line-height: 20px;
        padding-bottom: 10px;
      }
    }
  }

  .card-list-completes,
  .card-list-country,
  .card-list-end-date,
  .card-list-progress {
    color: $secondary-text-color;
  }

  .card-list-completes,
  .card-list-country,
  .card-list-end-date,
  .card-list-name,
  .card-list-stage {
    margin: 0 10px;
  }

  .card-list-country {
    margin-right: 0;
    display: inline-block;
  }

  .card-list-completes,
  .card-list-country,
  .card-list-end-date,
  .card-list-name {
    vertical-align: middle;
  }

  .card-list-end-date {
    // display: inline-block;
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
  }
}

.card-list-stage .stage {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  overflow: hidden;
  border-radius: 50%;
  padding: 0;
  margin: 0 2px;
  background: #f2f2f2;
  text-indent: -9999px;
  color: transparent;
  line-height: 16px;

  &.active {
    background: $secondary-text-color;
  }
}

.list {
  .list-only {
    display: auto;
  }

  .grid-only {
    display: none !important;
  }
}

.grid {
  .grid-only {
    display: auto;
  }

  .list-only {
    display: none !important;
  }
}

.grid {
  .card-list-item {
    //   position: relative;
    // flex: 1 1 25%;
    //   vertical-align: top;
    //   height: 200px;
    //   width: 250px;
    @media screen and (max-width: 600px) {
      //     display: block;
      width: auto;
      width: 100%;
      //     height: 150px;
      //     margin: 10px auto;
      //     flex: 1 1 100%;
    }

    .plus-card {
      .card-body {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 10px;
      }
    }
  }

  .pull-right {
    float: none;
  }

  .card {
    height: 200px;
    width: 250px;
    @media screen and (max-width: 600px) {
      height: 150px;
      width: 100%;
    }
  }

  .card-list-name {
    display: block;
    max-width: 80%;
    font-size: 16px;
    line-height: 20px;
  }

  .card-list-country {
    font-size: 12px;
    line-height: 16px;
    // text-transform: uppercase;
  }

  .card-list-country,
  .card-list-end-date {
    color: $secondary-text-color;
  }
  // .card-list-end-date {
  //   &:before {
  //     content: 'Ends\00a0';
  //   }
  //
  //   &.ended:before {
  //     content: 'Ended\00a0';
  //   }
  // }
  .card-list-progress {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 20px;
    font-size: 13px;
  }

  .card-list-progress-bg {
    width: 40%;
    display: block;
    @media screen and (max-width: 200px) {
      display: none;
    }
  }

  .card-list-progress-action {
    position: absolute;
    // left: 20px;
    margin-left: -10px;
    top: 0;
    line-height: 40px;
  }

  .card-list-progress-labels {
    position: absolute;
    right: 20px;
    top: 0;
    line-height: 40px;
    @media screen and (max-width: 200px) {
      right: auto;
      left: 10px;
    }
  }

  .card-list-progress-label {
    line-height: 21px;
    font-size: 13px;
    font-weight: 400;
  }

  .card-list-completes {
    line-height: 21px;
    font-size: 13px;
    vertical-align: middle;
  }

  .card-list-stage {
    position: absolute;
    top: 20px;
    right: 20px;

    .stage {
      display: none;
    }

    .active {
      display: block;
    }
  }

  .card-list-end-date {
    font-size: 12px;
    line-height: 20px;
  }
}

.card-list-country {
  .avatar-title {
    height: 2.2rem;
    width: 2.2rem;
  }
}

.card-list-progress-label {
  vertical-align: middle;
  margin: 0 10px;
  color: $theme-color;
}

.card-list-progress-bg {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  width: 100px;
  height: 4px;
  border-radius: 2px;
  overflow: hidden;
  background: #eee;
}

.card-list-progress-fg {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  left: 0;
  margin: 0;
  background: $theme-color;
}

.card-entity-details {
  ul:first-of-type {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li:first-of-type {
      float: left;
      margin-left: 16px;
      margin-top: 16px;
    }

    li:last-of-type {
      float: right;
      margin-right: 16px;
      margin-top: 16px;
    }
  }

  .header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: relative;
    text-align: center;
    padding: 30px 0 20px;
    z-index: 1;

    h1 {
      font-size: 22px;
      margin: 10px 0 0;
      padding: 0;
    }

    h2 {
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 0.5px;
      margin: 0;
      padding: 0;
    }
  }

  p {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: relative;
    text-align: center;
    padding: 30px 0 20px;
    z-index: 1;
  }
  @media screen and (max-width: 600px) {
    border: none !important;
  }
}

.members-card-wrapper {
  position: relative;
  overflow: hidden;
  margin: 0 auto;

  .members-card {
    float: left;
    width: 100%;
    overflow: hidden;

    .member-card {
      float: left;
      width: 100%;
      margin-top: 18px;
      padding: 18px 20px;
      border-radius: 5px;
      text-decoration: none;
      position: relative;
      opacity: 1;
      -webkit-transform: translateY(0px);
      -moz-transform: translateY(0px);
      -ms-transform: translateY(0px);
      -o-transform: translateY(0px);
      transform: translateY(0px);
      // -webkit-transition: all 0.7s;
      // -moz-transition: all 0.7s;
      // -o-transition: all 0.7s;
      // transition: all 0.7s;

      &:nth-of-type(1) {
        // transition-delay: 0.5s;
        margin-top: 0;
      }
      //
      // &:nth-of-type(2) {
      //   transition-delay: 0.7s;
      // }
      //
      // &:nth-of-type(3) {
      //   transition-delay: 0.9s;
      // }

      .btn-group {
        position: absolute;
        right: 20px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
      }

      .avatar {
        float: left;
        border-radius: 50%;
        margin-right: 20px;
      }

      .content {
        h1 {
          font-size: 18px;
          font-weight: 400;
          letter-spacing: 0.4px;
          margin-bottom: 0;
        }

        span {
          font-size: 14px;
          display: block;
          letter-spacing: 0.4px;
        }
      }

      @media (min-width: 414px) {
        padding: 18px 30px;
      }
    }
  }

  @media (min-width: 1024px) {
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // -webkit-transform: translate(-50%, -50%);
    // -moz-transform: translate(-50%, -50%);
    // -ms-transform: translate(-50%, -50%);
    // -o-transform: translate(-50%, -50%);
    // transform: translate(-50%, -50%);
  }
}
