.tree-check {
  list-style: none;
  margin: 0;
  padding-left: 25px;
  line-height: 2em;
  margin-bottom: 2em;

  li {
    // margin-left: 23px;
    position: relative;
    &:before {
      position: absolute;
      top: 0;
      left: -15px;
      display: block;
      width: 15px;
      height: 1em;
      content: "";
      border-bottom: 2px dotted var(--bs-body-color);
      border-left: 2px dotted var(--bs-body-color);
    }
    &:first-child:before {
      border-left: none;
    }
    &:after {
      position: absolute;
      top: 1.1em;
      bottom: 1px;
      left: -15px;
      display: block;
      content: "";
      border-left: 2px dotted var(--bs-body-color);
    }
    &:last-child:after {
      display: none;
    }
    .toggle-icon {
      position: relative;
      z-index: 1;
      display: inline-block;
      width: 14px;
      margin-right: 2px;
      margin-left: -23px;
      line-height: 14px;
      text-align: center;
      cursor: pointer;
      background-color: var(--bs-body-bg);
      border: 1px solid var(--bs-border-color);
      border-radius: 2px;
    }
    span {
      position: relative;
      top: -2px;
      left: 5px;
    }

    .check, .radio {
      // height: 20px;
      // width: 20px;
      position: relative;
      &:after {
        content: '\00D7';
        display: block;
        background: var(--bs-body-bg);
        background-image: url('/assets/images/icons/675702.png');
        pointer-events: none;
        font-size: 15px;
        position: absolute;
        top: -1px;
        left: -1px;
        height: 15px;
        width: 15px;
        color: transparent;
        transition: .25s all ease-in-out;
        border-radius: 3px;
        line-height: 15px;
        background-position: 0 15px;
        background-repeat: no-repeat;
        background-size: 15px 13px;
        border-width: 1px;
        border-style: solid;
        border-color: transparent;
        border-color: var(--bs-body-color);
      }
      &:checked {
        &:hover:after {
          // border-color: var(--bs-primary);
          border-color: var(--bs-body-bg);
        }
        &:after {
          background-color: var(--bs-primary);
          border-color: var(--bs-primary);
          background-position: 0 0;
        }
      }
      &:hover:after {
        // border-color: var(--bs-body-color);
        border-color: var(--bs-primary);
      }
    }

    .radio{&:after{ border-radius: 50%; }}
    label{cursor: pointer;}
  }

  .tree-head {
    font-weight: bold;
    cursor: pointer;
    margin-left: 0;
  }
}