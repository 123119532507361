form.verificationForm {
  .form-control {
    display: block;
    height: 50px;
    margin-right: 0.5rem;
    text-align: center;
    font-size: 1.25rem;
    min-width: 0;
    caret-color: transparent;
    user-select: none;
    max-width: 3em;

    &:last-child {
      margin-right: 0;
    }
  }
}

.hidden {
  display: none;
}

.disabledCursor {
  cursor: default;
}
