$size: 26px;
@mixin circle($size) {
  content: "";
  position: absolute;
  border-radius: 50%;
  left: calc(50% - #{$size/2});
  top: calc(50% - #{$size/2});
  width: $size;
  height: $size;
}

.progress-spinner {
  width: $size;
  height: $size;
  border-radius: 50%;
  background-color: #E5E5E5;
  position: relative;

  &.gt-50 {
    background-color: var(--bs-primary);
  }
}

.ppc-progress {
  @include circle($size);
  clip: rect(0, $size, $size, #{$size/2});

  .ppc-progress-fill {
    @include circle($size);
    clip: rect(0, #{$size/2}, $size, 0);
    background: var(--bs-primary);
    transform: rotate(60deg);
  }

  .gt-50 & {
    clip: rect(0, #{$size/2}, $size, 0);

    .ppc-progress-fill {
      clip: rect(0, $size, $size, #{$size/2});
    }
  }
}

.ppc-percents {
  // @include circle(#{$size/1.15});
  @include circle(#{$size/1.2});
  text-align: center;
  display: table;

  span {
    display: block;
    font-weight: bold;
    color: var(--bs-primary);

    svg {
      display: block;

      &.icon,
      &.tick {
        // position: absolute;
      }

      &.tick {
        fill: none;
      }

      &.icon {
        width: 10px;
        height: 10px;
        fill: var(--bs-primary);
        z-index: 1;
        stroke-width: .5;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke: var(--bs-primary);
        transition: opacity 0.2s, transform 0.2s;
        transform: translateX(5.5px);
      }

      &.tick {
        width: 20px;
        height: 20px;
        stroke-width: 3;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke: var(--bs-primary);
        transition: stroke 0.3s ease 0.7s;
      }

      &.complete {
        animation: tick 0.3s linear forwards 0.4s;
      }

    }
  }
}

.pcc-percents-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.progress-spinner {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  margin-top: -3.5px;
}
@keyframes tick {
  100% {
    transform: rotate(-90deg) translate(0, -5px) scale(var(--progress-scale));
  }
}
