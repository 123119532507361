 .chat-guests .guest-wrapper {
  padding: 5px 5px;
  padding: 5px 0px;
  border-radius: 5em;
  // box-shadow: 0px 1px 2px rgba(0, 0, 0, .3);
  display: flex;
  justify-items: center;
  align-items: center;
  text-align: left;
  margin: 5px;
  gap: .5em;
  position: relative;
  min-width: 150px;

  .avatar {
    &:first-of-type {
      margin-right: -20px;
    }
    // height: 100%;
    border-radius: 50%;
    background:rgba(114, 105, 239, 0.25);
    border: 2px solid var(--bs-border-color);
    padding: 3px 3px 2.5px 3px;
    // position: absolute;
  }

  .right {
    display: flex;
    align-items: flex-start;
    justify-items: center;
    flex-direction: column;
    width: 100%;
    padding-right: 5px;
    
    .name {
      h5 {
        margin-bottom: 0;
        font-size: 12px;
        // font-size: 1.5vw;
      }
    }

    .location {
      color: var(--bs-text-muted-color);
      font-size: 14px;
    }
  }
}