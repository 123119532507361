//
// _buttons.scss
//

a,
button {
  outline: none !important;
}
// Rounded Buttons

.btn-rounded {
  border-radius: 30px;
  border-radius: 30px;
}

.btn-dark,
.btn-secondary {
  color: $gray-200 !important;
}

.btn-outline-light {
  color: $gray-900;
}

button {
  // transition: all ease-out 0.5s !important;
  div{
    margin: 10px auto;
  }

  &:hover {
    // transform:scale(1.1);
  }

  .active {
    transition-delay: 1s;
    width: 10px;
  }

  &.btn-loading {
    border-radius: 40px;
    width: 40px;
    height: 40px;
    font-size: 0;
    margin: 0 auto;
  }
}

.btn-spinner {
  display: block;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 4px;
  margin: 0 auto;
  left: 0;
  right: 0;
  background: transparent;
  box-sizing: border-box;
  border-top: 4px solid white;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-radius: 100%;
  animation-delay: 0.5s;
  animation: spin 0.6s ease-out infinite;
  transition: all ease 0.5s;
}

// theme dark
[data-theme="dark"] {
  .btn-light {
    color: var(--#{$variable-prefix}dark);
    background-color: var(--#{$variable-prefix}light);
    border-color: var(--#{$variable-prefix}light);

    &:hover {
      color: var(--#{$variable-prefix}dark);
      background-color: rgba(var(--#{$variable-prefix}light-rgb), 0.75);
      border-color: rgba(var(--#{$variable-prefix}light-rgb), 0.75);
    }
  }

  .btn-check:focus+.btn-light,
  .btn-check:focus+.btn-outline-light,
  .btn-check:focus+.btn-soft-light,
  .btn-light:focus,
  .btn-outline-light:focus,
  .btn-soft-light:focus {
    color: var(--#{$variable-prefix}dark);
    background-color: rgba(var(--#{$variable-prefix}light-rgb), 0.75);
    border-color: rgba(var(--#{$variable-prefix}light-rgb), 0.75);
  }

  .btn-check:active+.btn-light,
  .btn-check:active+.btn-outline-light,
  .btn-check:active+.btn-soft-light,
  .btn-check:checked+.btn-light,
  .btn-check:checked+.btn-outline-light,
  .btn-check:checked+.btn-soft-light,
  .btn-light.active,
  .btn-light:active,
  .btn-outline-light.active,
  .btn-outline-light:active,
  .btn-soft-light.active,
  .btn-soft-light:active,
  .show>.btn-light.dropdown-toggle,
  .show>.btn-outline-light.dropdown-toggle,
  .show>.btn-soft-light.dropdown-toggle {
    color: var(--#{$variable-prefix}dark);
    background-color: rgba(var(--#{$variable-prefix}light-rgb), 0.75);
    border-color: rgba(var(--#{$variable-prefix}light-rgb), 0.75);
  }

  // dark

  .btn-dark {
    color: var(--#{$variable-prefix}light);
    background-color: var(--#{$variable-prefix}dark);
    border-color: var(--#{$variable-prefix}dark);

    &:hover {
      color: var(--#{$variable-prefix}light);
      background-color: rgba(var(--#{$variable-prefix}dark-rgb), 0.75);
      border-color: rgba(var(--#{$variable-prefix}dark-rgb), 0.75);
    }
  }

  .btn-check:focus+.btn-dark,
  .btn-check:focus+.btn-outline-dark,
  .btn-check:focus+.btn-soft-dark,
  .btn-dark:focus,
  .btn-outline-dark:focus,
  .btn-soft-dark:focus {
    color: var(--#{$variable-prefix}light);
    background-color: rgba(var(--#{$variable-prefix}dark-rgb), 0.75);
    border-color: rgba(var(--#{$variable-prefix}dark-rgb), 0.75);
  }

  .btn-check:active+.btn-dark,
  .btn-check:active+.btn-outline-dark,
  .btn-check:active+.btn-soft-dark,
  .btn-check:checked+.btn-dark,
  .btn-check:checked+.btn-outline-dark,
  .btn-check:checked+.btn-soft-dark,
  .btn-dark.active,
  .btn-dark:active,
  .btn-outline-dark.active,
  .btn-outline-dark:active,
  .btn-soft-dark.active,
  .btn-soft-dark:active,
  .show>.btn-dark.dropdown-toggle,
  .show>.btn-outline-dark.dropdown-toggle,
  .show>.btn-soft-dark.dropdown-toggle {
    color: var(--#{$variable-prefix}light);
    background-color: rgba(var(--#{$variable-prefix}dark-rgb), 0.75);
    border-color: rgba(var(--#{$variable-prefix}dark-rgb), 0.75);
  }

  // outline

  .btn-outline-light {
    color: var(--#{$variable-prefix}dark);
    border-color: var(--#{$variable-prefix}light);

    &:hover {
      color: var(--#{$variable-prefix}dark);
      background-color: rgba(var(--#{$variable-prefix}light-rgb), 0.75);
      border-color: rgba(var(--#{$variable-prefix}light-rgb), 0.75);
    }
  }

  .btn-outline-dark {
    color: var(--#{$variable-prefix}dark);
    border-color: var(--#{$variable-prefix}dark);

    &:hover {
      color: var(--#{$variable-prefix}light);
      background-color: rgba(var(--#{$variable-prefix}dark-rgb), 0.75);
      border-color: rgba(var(--#{$variable-prefix}dark-rgb), 0.75);
    }
  }

  // soft

  .btn-soft-light {
    color: var(--#{$variable-prefix}dark);
    background-color: rgba(var(--#{$variable-prefix}light-rgb), 0.1);

    &:hover {
      color: var(--#{$variable-prefix}dark);
      background-color: var(--#{$variable-prefix}light);
    }
  }

  .btn-soft-dark {
    color: var(--#{$variable-prefix}dark);
    background-color: rgba(var(--#{$variable-prefix}dark-rgb), 0.1);

    &:hover {
      color: var(--#{$variable-prefix}light);
      background-color: var(--#{$variable-prefix}dark);
    }
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

// .btn-loading {
//   position: relative;
//   left: -9999px;
//   width: 10px;
//   height: 10px;
//   border-radius: 5px;
//   background-color: white;
//   color: white;
//   box-shadow: 9984px 0 0 0 white, 9999px 0 0 0 white, 10014px 0 0 0 white;
//   animation: btnLoading 1.5s infinite linear;
// }

@keyframes btnLoading {
  0% {
    box-shadow: 9984px 0 0 0 white, 9999px 0 0 0 white, 10014px 0 0 0 white;
  }

  16.667% {
    box-shadow: 9984px -10px 0 0 white, 9999px 0 0 0 white, 10014px 0 0 0 white;
  }

  33.333% {
    box-shadow: 9984px 0 0 0 white, 9999px 0 0 0 white, 10014px 0 0 0 white;
  }

  50% {
    box-shadow: 9984px 0 0 0 white, 9999px -10px 0 0 white, 10014px 0 0 0 white;
  }

  66.667% {
    box-shadow: 9984px 0 0 0 white, 9999px 0 0 0 white, 10014px 0 0 0 white;
  }

  83.333% {
    box-shadow: 9984px 0 0 0 white, 9999px 0 0 0 white, 10014px -10px 0 0 white;
  }

  100% {
    box-shadow: 9984px 0 0 0 white, 9999px 0 0 0 white, 10014px 0 0 0 white;
  }
}
